.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

sup {
  font-size: 10px
}

.admin-wrap{
  display: flex;
  background-color: #fafafa;
}

.admin-wrap > div:first-child{
  width: 100%;
}

@media (max-width: 475px) {
  .admin-wrap > div:first-child {
    width: auto;
  }
  .admin-wrap > div:nth-child(2) {
    margin-top: 46px;
  }
  .admin-wrap > div:nth-child(3) {
    margin-top: 46px;
  }
}

.headroom--pinned{
  top: 48px!important;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  z-index: 1001!important;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

div[role="tooltip"] {
  z-index: 9999999;
}
